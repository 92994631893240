import React from 'react';
import QuoteLayout from '../../components/QuoteLayout/quote-layout';
import QuoteContext from '../../context/quote-context';
import Error from '../../components/GetQuoteSteps/error';

export default function ErrorPage() {

  return(
    <QuoteLayout>
      <QuoteContext.Consumer>
        {quote => (
          <Error
            address={quote.address}
            peopleCount={quote.peopleCount}
            meal={quote.meal}
            hob={quote.hob}
            hobCount={quote.hobCount}
            oven={quote.oven}
            date={quote.date}
            courses={quote.courses}
            dietaryRestrictions={quote.dietaryRestrictions}
            vegetarian={quote.vegetarian}
            vegan={quote.vegan}
            noGluten={quote.noGluten}
            noNuts={quote.noNuts}
            noShellfish={quote.noShellfish}
            noDairy={quote.noDairy}
            otherRestrictions={quote.otherRestrictions}
            name={quote.name}
            email={quote.name}
            phone={quote.phone}
            notes={quote.notes}
          />
        )}
      </QuoteContext.Consumer>
    </QuoteLayout>
  );
}
