import React from 'react';
import { useIntl } from 'react-intl';
import { LocalizedLink as Link } from 'gatsby-theme-i18n';
import config from "../../../data/SiteConfig";
import styles from './steps.module.scss';

function encode(data) {
  return Object.keys(data)
    .map(key => `${encodeURIComponent(`${key}: ${data[key]}`)}`)
    .join('<br/>')
}

export default function Error({
  address,
  peopleCount,
  meal,
  hob,
  hobCount,
  oven,
  date,
  courses,
  dietaryRestrictions,
  vegetarian,
  vegan,
  noGluten,
  noNuts,
  noShellfish,
  noDairy,
  otherRestrictions,
  name,
  email,
  phone,
  notes,
}) {
  const d = {
    address,
    peopleCount,
    meal,
    hob,
    hobCount,
    oven,
    date: date.toLocaleString(),
    courses,
    dietaryRestrictions,
    vegetarian,
    vegan,
    noGluten,
    noNuts,
    noShellfish,
    noDairy,
    otherRestrictions,
    name,
    email,
    phone,
    notes
  };

  const intl = useIntl();
  const subject = encodeURIComponent(`Personal chef request from ${name} (${meal} for ${peopleCount})`);
  const body = encode({...d});

  return(
    <div className={styles.finishContainer}>
      <h1 className={styles.finishHeadline}>{intl.formatMessage({ id: "quoteErrorHeadline" })}</h1>
      <p className={styles.finishParagraph}>
        {intl.formatMessage({ id: "quoteErrorDescription" })}
        <a
          style={{color: '#369EF7'}}
          href={`mailto:${config.userEmail}?subject=${subject}&body=${body}`}
          target="_blank"
          rel="noreferrer"
        >
          {intl.formatMessage({ id: "quoteErrorCta" })}
        </a>
        .
      </p>
      <Link to={'/'}>
        <div className={styles.finishLink}>{intl.formatMessage({ id: "quoteBackToHome" })}</div>
      </Link>
    </div>
  );
}
